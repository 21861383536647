function generateCarouselElements(jsonData) {
    console.log(jsonData);
    const carouselContainerThumbnails = document.getElementById('carouselSectionsSecondaryThumbnails');
    const carouselContainerBig = document.getElementById('carouselSectionsSecondaryBig');
    const carouselSectionsSecondaryDots = document.getElementById('carouselSectionsSecondaryDots');
    const carouselSectionsSecondaryBigDots = document.getElementById('carouselSectionsSecondaryBigDots');

    let carouselHTMLBig = '';
    let carouselHTMLThumbnails = '';
    let dotsHTML = '';

    jsonData.forEach((item, index) => {
        carouselHTMLThumbnails += `
            <div class="carousel-section">
                <div class="content">
                    <button title="Go to slide ${index}" onclick="Carousel('secondary-big').goto(${index})" style="background-image: url('https://img.youtube.com/vi/${item.youtubeId}/mqdefault.jpg')"></button>
                </div>
            </div>
        `;

        carouselHTMLBig += `
            <div class="carousel-section">
                <div class="content" style="background-image: url('https://img.youtube.com/vi/${item.youtubeId}/maxresdefault.jpg')">
                    <iframe class="iframe" width="560" height="315" data-src="https://www.youtube.com/embed/${item.youtubeId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <h2>${item.title}</h2>                        
                </div>
            </div>
        `;

        dotsHTML += `<div class="carousel-dot"></div>`;
    });

    carouselContainerThumbnails.innerHTML = carouselHTMLThumbnails;
    carouselContainerBig.innerHTML = carouselHTMLBig;
    carouselSectionsSecondaryDots.innerHTML = dotsHTML;
    carouselSectionsSecondaryBigDots.innerHTML = dotsHTML;
}

function fetchJSONData(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                callback(null, JSON.parse(xhr.responseText));
            } else {
                callback(xhr.status, null);
            }
        }
    };
    xhr.send();
}
/*
fetchJSONData('/json/youtube.json', function (error, jsonData) {
    if (error) {
        console.error('Error fetching JSON data:', error);
    } else {
        generateCarouselElements(jsonData);
        initCarouselByCarouselId('carouselSecondaryBig');
        initCarouselByCarouselId('carouselSecondaryThumbnails');
    }
});
*/
function initCarouselByCarouselId(id) {
    document.getElementById(id).classList.add("carousel");
    createCarousel(document.getElementById(id));
}

